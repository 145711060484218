// Gray
@gray-3: #f5f5f5;
@gray-5: #d9d9d9;
@gray-6: #bfbfbf;
@gray-7: #8c8c8c;

.main-header-dropdown {

  .ant-dropdown-menu {
    min-width: 200px;
    border: 1px solid rgba(72, 94, 144, 0.16);
  }

  .ant-dropdown-menu-title-content {
    font-size: 13px;

    a {
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .avatar-menu-item {
    padding: 0px;
  }

  .avatar-wrapper {
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
  }
}

.main-menu-component {
  .dropdown-main-menu-item {
    &.ant-menu-item-selected {
      background: rgb(#023e7d, .1) !important;
    }

    &:active {
      background: transparent;
    }
  }
}

.dropdown-main-menu {
  min-width: 200px !important;
  border: 1px solid rgba(72, 94, 144, 0.16);

  .ant-menu {
    padding: 10px 0px !important;

    li {
      height: 30px;
      line-height: 30px;
      margin: 0px !important;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .dropdown-main-menu-item {
    &.ant-menu-item-selected {
      background: rgb(#023e7d, .1) !important;
    }

    &:active {
      background: transparent;
    }
  }
}

.vui-header {
  display: grid;
  grid-template-columns: 225px 6fr 2fr;
  grid-template-rows: 60px;
  align-items: center;
  background: white;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  padding: 0 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .menu-icon {
    margin-right: 20px;
    display: none;
  }

  .brand-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(72, 94, 144, 0.16);

    .logo {
      height: 28px;
      width: auto;
      padding-left: 10px;
    }
  }

  .navigation-wrapper {
    height: 100%;

    .ant-menu {
      height: 100%;
      line-height: unset;
      border-bottom: none;

      li, .ant-menu-submenu-title {
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    }
  }

  .profile-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .auth-wrapper {
      height: 100%;
      margin-left: 20px;

      .auth-spaces, .ant-space-item, .ant-dropdown-trigger {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .ant-dropdown-trigger {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .brand-wrapper {
      display: flex;
      align-items: center;
      border-right: 0;

      .logo {
        height: 26px;
        width: auto;
      }
    }

    .navigation-wrapper {
      display: none;
    }

    .menu-icon {
      display: block;
    }
  }
}

.header-notification-drawer {
  .ant-drawer-content-wrapper {
    min-width: 480px;
    max-width: 480px;
  }

  .ant-drawer-header {
    text-transform: uppercase;
  }

  .ant-drawer-body {
    padding: 0px;
    overflow: unset;
  }

  .action-wrapper {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;

    .mark-all {
      cursor: pointer;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }

    .switch-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: @gray-7;

      > span {
        margin-right: 5px;
      }
    }
  }

  .empty-wrapper {
    padding: 9rem 0;
    text-align: center;
    width: 60%;
    margin: auto;

    .icon {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .main-notif-wrapper {
    .segment-wrapper {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .segment-title {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      padding: 0px 25px;
      margin-bottom: 5px;
      color: @gray-7;
    }

    .notif-list {
      padding: 0px 12.5px;

      .notif-item {
        padding: 12.5px;
        cursor: pointer;
        border-radius: 10px;
        background: transparent;
        transition: all 0.5s ease;
        display: flex;
        gap: 15px;

        &:hover {
          background: @gray-3;

          .status-wrapper {
            .status {
              background: @gray-5;
            }
          }
        }

        .info-wrapper {
          flex: 1;

          .info-title {
            font-weight: 500;
            margin-bottom: 0px;
          }

          .info-description {
            font-size: 12px;
            margin-bottom: 0px;
          }

          .info-timestamp {
            font-size: 12px;
            color: @gray-7;
            font-weight: 500;
          }
        }

        .status-wrapper {
          position: relative;
          
          .status {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background: transparent;
            transition: all 0.5s ease;

            &:after {
              display: none;
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              border-radius: 100%;
              background: @primary-color;
            }

            &.unread {
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: @screen-lg) {
    .ant-drawer-content-wrapper {
      min-width: unset;
      max-width: 480px;
      width: 100% !important;
    }
  }
}

@primary-color: #897E6B;@screen-xs: 480px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@screen-xs-min: 0px;@screen-sm-min: 480px;@screen-md-min: 576px;@screen-lg-min: 768px;@screen-xl-min: 992px;